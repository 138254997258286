import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { fetchDiscountSubscriptions, resetSubscription } from 'store/subscriptions/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { PRODUCT_CODES, SUBSCRIPTION_ACTIVE } from 'constants/payments';

import { OfferTypes, cancelFlow_offerResultScreenLoad } from 'services/analytics/trackers/cancelFlow';

import { useSelector } from 'hooks/store';
import { useModals } from 'hooks/modals';

import { getPriceFromCents } from 'helpers/prices';

import { PaymentProviders } from 'types/subscription';
import { ModalName } from 'types/modals';

const useReturnOffer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();

    const { discountSubscription, subscriptions } = useSelector(selectSubscriptions);

    const resetCandidate = subscriptions.find(
        ({ state, product_code, discounts, cancelled_at, internal_cancel_at }) => {
            return (
                !discounts.discount &&
                state === SUBSCRIPTION_ACTIVE &&
                (cancelled_at || internal_cancel_at) &&
                product_code === PRODUCT_CODES.APP_FULL_ACCESS
            );
        }
    );

    const withFee = !!resetCandidate?.fee?.fee_expired_at;

    useEffect(() => {
        if (!resetCandidate || discountSubscription || withFee) return;

        if (resetCandidate.payment_provider === PaymentProviders.Solidgate) {
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.extra,
                    external_id: resetCandidate.external_id,
                })
            );
        }
    }, [discountSubscription, resetCandidate]);

    const hasOfferFetched = resetCandidate && discountSubscription;

    const resetCandidatePriceData = resetCandidate
        ? {
              currency: resetCandidate.product.currency,
              period: resetCandidate.subscription_period,
              price: `${getPriceFromCents(resetCandidate.product.amount)}`,
          }
        : null;

    const discountPriceData = discountSubscription
        ? {
              currency: discountSubscription!.currency,
              period: discountSubscription!.subscription_period,
              price: `${getPriceFromCents(discountSubscription!.amount)}`,
          }
        : null;

    const onResetSubscription = useCallback(() => {
        if (resetCandidate && discountSubscription) {
            openModal(ModalName.WAITING_MODAL, { title: 'subscription.modals.updating' });

            dispatch(
                resetSubscription({
                    external_id: resetCandidate.external_id,
                    product: discountSubscription,
                    discount_type: DISCOUNT_TYPES.extra,
                    onSuccess: () => {
                        closeModal();
                    },
                    onError: () => {
                        openModal(ModalName.WARNING_MODAL, {
                            offerType: OfferTypes.Return_offer,
                            onMainBtnClick: onResetSubscription,
                            title: t('subscription.cancelFlow.errorModal.title'),
                            analyticScreenLoadHandler() {
                                cancelFlow_offerResultScreenLoad({
                                    result: 'fail',
                                    offer_type: OfferTypes.Return_offer,
                                });
                            },
                        });
                    },
                })
            );
        }
    }, [resetCandidate, discountSubscription]);

    return {
        withFee,
        resetCandidate,
        hasOfferFetched,
        discountSubscription,
        discountPriceData,
        resetCandidatePriceData,
        onResetSubscription,
    };
};

export default useReturnOffer;
