import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    gap: 16px;
    align-items: center;
    max-width: 432px;
    margin: 0 auto;
`;

export const Form = styled.form`
    width: 100%;
`;

export const ContentBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 0;
`;

export const TextAreaTip = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

export const Textarea = styled.textarea<{ hasError: boolean }>`
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    border-width: 1px;
    resize: none;
    border-radius: ${({ theme }) => `${theme['border-radius-medium']}px`};
    background-color: ${color('surface-default')};
    border-color: ${({ hasError }) => (!hasError ? color('surface-default') : color('critical-default'))};
    color: ${color('text-main')};

    &:focus {
        background-color: ${color('surface-hovered')};
        border-color: ${({ hasError }) => (!hasError ? color('safe') : color('critical-default'))};
        outline: none;
    }

    &:disabled {
        background-color: ${color('surface-secondary')};
        border-color: transparent;
    }

    &:disabled::placeholder,
    &:disabled {
        color: ${color('text-secondary-subdued')};
    }
`;
