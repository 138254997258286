import ReactModal, { Styles } from 'react-modal';
import React from 'react';

import { WarningModalContainerProps } from 'containers/modals/WarningModalContainer/types';
import { SuccessModalContainerProps } from 'containers/modals/SuccessModalContainer/types';

export enum ModalName {
    FEE_ALERT = 'feeAlert',
    SPECIAL_OFFER = 'specialOffer',
    RETURN_OFFER = 'returnOffer',
    FEEDBACK_FORM = 'feedbackForm',
    ALERT_MODAL = 'alertModal',
    REASON_MODAL = 'reasonModal',
    REMINDER_MODAL = 'reminderModal',
    PAUSE_MODAL = 'pauseModal',
    FREE_MONTH_MODAL = 'freeMonthModal',
    FREEZE_ALERT_MODAL = 'freezeAlertModal',
    FREEZE_REASON_MODAL = 'freezeReasonModal',
    FREEZE_PERIOD_MODAL = 'freezePeriodModal',
    FREEZE_NOT_AVAILABLE_MODAL = 'freezeNotAvailableModal',
    REMINDER_HAS_BEEN_SET_MODAL = 'reminderHasBeenSet',
    SUCCESS_MODAL = 'successModal',
    WARNING_MODAL = 'warningModal',
    WAITING_MODAL = 'waitingModal',
    ERROR_MODAL = 'errorModal',
    DELETE_ACCOUNT = 'deleteAccount',
    DELETE_ACCOUNT_SUCCESS = 'deleteAccountSuccess',
    DELETE_ACCOUNT_ERROR = 'deleteAccountError',
}

export type ModalsType = {
    [ModalName.FEE_ALERT]: () => JSX.Element | null;
    [ModalName.SPECIAL_OFFER]: ({ onError }: { onError?: () => void }) => JSX.Element | null;
    [ModalName.RETURN_OFFER]: () => JSX.Element | null;
    [ModalName.FEEDBACK_FORM]: () => JSX.Element | null;
    [ModalName.ALERT_MODAL]: () => JSX.Element | null;
    [ModalName.REASON_MODAL]: () => JSX.Element | null;
    [ModalName.REMINDER_MODAL]: () => JSX.Element | null;
    [ModalName.PAUSE_MODAL]: () => JSX.Element | null;
    [ModalName.FREE_MONTH_MODAL]: () => JSX.Element | null;
    [ModalName.WAITING_MODAL]: () => JSX.Element | null;
    [ModalName.FREEZE_ALERT_MODAL]: () => JSX.Element | null;
    [ModalName.FREEZE_REASON_MODAL]: () => JSX.Element | null;
    [ModalName.FREEZE_PERIOD_MODAL]: () => JSX.Element | null;
    [ModalName.FREEZE_NOT_AVAILABLE_MODAL]: () => JSX.Element | null;
    [ModalName.REMINDER_HAS_BEEN_SET_MODAL]: () => JSX.Element | null;
    [ModalName.SUCCESS_MODAL]: (props: SuccessModalContainerProps) => JSX.Element | null;
    [ModalName.WARNING_MODAL]: (props: WarningModalContainerProps) => JSX.Element | null;
    [ModalName.ERROR_MODAL]: ({ tryAgain }: { tryAgain: () => void }) => JSX.Element | null;
    [ModalName.DELETE_ACCOUNT]: () => JSX.Element | null;
    [ModalName.DELETE_ACCOUNT_SUCCESS]: () => JSX.Element | null;
    [ModalName.DELETE_ACCOUNT_ERROR]: () => JSX.Element | null;
};

export type CommonModalProps = ReactModal.Props & {
    onClose?: () => void;
    closeOnOverlayClick?: boolean;
};

export interface IModal extends CommonModalProps {
    children: React.ReactNode;
    withCloseButton?: boolean;
    fullscreen?: boolean;
    dataLocator?: string;
    customStyles?: Styles;
    closeBtnLocator?: string;
}

export interface IAlertModal extends Omit<CommonModalProps, 'isOpen'> {
    onSubmit?: () => void;
    onDiscard?: () => void;
    onFreezeSubscription?: () => void;
    captions: {
        title: JSX.Element;
        description: JSX.Element;
        submitButton?: string;
        discardButton?: string;
        freezeButton?: string;
    };
    dataLocator?: string;
    isFreezeSubscription?: boolean;
    withoutIcon?: boolean;
}

export interface ICancellationReasonModal extends Omit<CommonModalProps, 'isOpen'> {
    onSubmit: (reason: string) => void;
    onCancel: () => void;
    onClose: () => void;
    isFreezeSubscription?: boolean;
}

export interface IFreezePeriodModal extends Omit<CommonModalProps, 'isOpen'> {
    onSubmit: (reason: string) => void;
    onDiscard: () => void;
    onClose: () => void;
}

export interface IWaitingModal extends Omit<CommonModalProps, 'isOpen'> {
    captions: {
        title: string;
    };
    dataLocator?: string;
}
